<template>
  <h1>Das ist das Impressum</h1>
</template>

<script>
export default {
  name: "Impressum"
};
</script>

<style scoped lang="scss">
</style>